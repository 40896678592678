<template>
  <div class="menu-dropdown-item-icon" @mouseenter="show = true" @mouseleave="show = false">
    <k-link :to="to" :link="link">
      <font-awesome-icon :to="{}" :icon="icon" @click="onClick" />
    </k-link>
  </div>
</template>

<script>
export default {
  name: 'MenuIcon',
  components: {
    KLink: () =>
      import(/* webpackChunkName: "k-link" */ 'adready-vue/components/elements/k-link.vue'),
  },

  props: {
    icon: {
      required: true,
      type: Array,
      default: () => [''],
    },
    title: {
      required: false,
      type: String,
      default: () => '',
    },
    to: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    link: {
      required: false,
      type: String,
      default: () => null,
    },
  },

  data() {
    return {
      show: false, // initial state for title
    };
  },

  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-dropdown-item-icon {
  padding: 0 30px 0 0;
  font-size: 16px;
  font-weight: bold;
  color: $grey_0;
  cursor: pointer;

  a {
    color: $grey_0;
  }
}
</style>
